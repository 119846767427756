import React, {forwardRef, useEffect, useState} from 'react';
import {
  QueryResponseProvider,
  useQueryResponse,
  useQueryResponseData,
  useQueryResponseLoading
} from "./QueryResponseProvider.tsx";
import {ListViewProvider, useListView} from "./ListViewProvider.tsx";
import {QueryRequestProvider, useQueryRequest} from "./QueryRequestProvider.tsx";
import {initialQueryState, KTCard, KTCardBody, KTIcon, useDebounce} from "../../helpers";
import {Column, ColumnInstance, Row, useTable} from "react-table";
import {WebhookLog} from "../../../app/modules/payment/core/_models.ts";
import CustomHeaderColumn from "./CustomHeaderColumn.tsx";
import {CustomRow} from "./CustomRow.tsx";
import ListPagination from "./ListPagination.tsx";
import ListLoading from "../components/ListLoading.tsx";
import {useTranslation} from "react-i18next";
import ActionButton, {ActionButtonProps} from "../components/ActionButton.tsx";
import {useQueryClient} from "react-query";
import ListFilter from "./ListFilter.tsx";
import HTooltip from "../components/HTooltip.tsx";

export interface PaginatedListContentProps<T extends object> {
  columns: ReadonlyArray<Column<T>>;
  title?: string | React.ReactNode;
  showSearch?: boolean;
  filters: Array<{ title?: string; name: string; options: Array<{ value: string; label: string }> }>;
  listActions: Array<ActionButtonProps>;
  queryList: string;
  empty?: React.ReactNode
}

const PaginatedListContent = forwardRef(function PaginatedListContent<T extends object>({
                                                  columns,
                                                  title,
                                                  showSearch = false,
                                                  filters,
                                                  listActions = [],
                                                  queryList,
                                                  actions,
                                                  empty
                                                }: PaginatedListContentProps<T>, ref) {
  // ref.current = this;
  const {t, i18n} = useTranslation()
  const isLoading = useQueryResponseLoading()
  const data = useQueryResponseData<T>()
  const {updateState} = useQueryRequest()
  const queryClient = useQueryClient()
  const [searchTerm, setSearchTerm] = useState<string>('')
  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
    columns,
    data,
  })
  const debouncedSearchTerm = useDebounce(searchTerm, 150)
  const {selected, clearSelected} = useListView()
  const {query} = useQueryResponse()

  useEffect(
    () => {
      if (debouncedSearchTerm !== undefined && searchTerm !== undefined) {
        updateState({search: debouncedSearchTerm, ...initialQueryState})
      }
    },
    [debouncedSearchTerm] // Only call effect if debounced search term changes
    // More details about useDebounce: https://usehooks.com/useDebounce/
  )

  return (
    <KTCard className="mb-5 mb-xl-10" id="kt_profile_details_view">
      <div className="card-header cursor-pointer">
        {title && (<div className="card-title m-0">{typeof title == 'string' ? (<h3 className="fw-bold m-0">{title}</h3>) : title}</div>)}
        {!title && showSearch && (
          <div className='d-flex align-items-center position-relative my-1 me-2'>
            <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6'/>
            <input
              type='text'
              data-kt-user-table-filter='search'
              className='form-control form-control-solid w-250px ps-14'
              placeholder={t('Search transaction')}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
        )}
        <div className="card-toolbar">
          <div className="d-flex justify-content-end align-items-center me-2">
            {title && showSearch && (
              <div className='d-flex align-items-center position-relative my-1 me-2'>
                <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6'/>
                <input
                  type='text'
                  data-kt-user-table-filter='search'
                  className='form-control form-control-solid w-250px ps-14'
                  placeholder={t('Search transaction')}
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>
            )}
            {(listActions.length == 0 || selected?.length == 0) && <ListFilter filters={filters}/>}
            {actions?.map(a => (
              <button key={a.title} onClick={a.onClick} type='button' className={a.className || 'btn btn-light-primary me-3'}>
                {a.icon}
                {a.title}
              </button>
            ))}
            {selected?.length > 0 && (
              <div className='d-flex justify-content-end align-items-center'>
                <div className='fw-bolder me-5'>
                  <span className='me-2'>{selected.length}</span> {t('Selected', {count: selected.length})}
                </div>
                {listActions.map((action, index) => (
                  <ActionButton
                    title={action.title}
                    action={action.action}
                    className={action.className}
                    onFinish={() => {
                      queryClient.invalidateQueries([`${queryList}-${query}`])
                      clearSelected();
                      // updateState({search: debouncedSearchTerm, filter, ...initialQueryState});
                    }}
                    data={{ids: selected}}
                    confirmation={action.confirmation}
                  />
                ))}
              </div>
            )}
          </div>
          <HTooltip title={t('Reload data')}>
            <a ref={ref} href="#" className="btn btn-icon btn-active-light-primary" onClick={() => {
              queryClient.invalidateQueries([`${queryList}-${query}`])
            }}>
              {!isLoading && <KTIcon iconName='loading' className='fs-1'/>}
              {isLoading && <span className='indicator-progress' style={{display: 'block'}}><span
                className='spinner-border spinner-border-sm align-middle ms-2'></span></span>}
            </a>
          </HTooltip>
        </div>
        {/*<button className="btn btn-sm btn-primary align-self-center" onClick={() => setModal(true)}>{t('Update Settings')}</button>*/}
      </div>
      <KTCardBody>
        <div className='table-responsive'>
          <table
            id='kt_table_users'
            className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
            {...getTableProps()}
          >
            <thead>
            <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
              {headers.map((column: ColumnInstance<WebhookLog>) => (
                <CustomHeaderColumn key={column.id} column={column}/>
              ))}
            </tr>
            </thead>
            <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
            {rows.length > 0 && (
              rows.map((row: Row<WebhookLog>, i) => {
                prepareRow(row)
                return <CustomRow row={row} key={`row-${i}-${row.id}`}/>
              })
            )}
            {(rows.length == 0 && !isLoading) && (
              <tr>
                <td colSpan={columns.length}>
                  {empty ? empty :
                    <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                      {t('No matching records found')}
                    </div>}
                </td>
              </tr>
            )}
            </tbody>
          </table>
        </div>
        <ListPagination />
        {isLoading && <ListLoading />}
      </KTCardBody>
    </KTCard>
  );
})

const PaginatedList = forwardRef(function PaginatedList<T extends object>({
                                           url,
                                           columns,
                                           title,
                                           showSearch,
                                           filters,
                                           listActions,
                                           queryList,
                                           actions,
                                           empty
                                         }: PaginatedListContentProps<T> & { url: string; }, ref) {
  return (
    <QueryRequestProvider>
      <QueryResponseProvider url={url} queryList={queryList}>
        <ListViewProvider>
          <PaginatedListContent
            columns={columns}
            title={title}
            showSearch={showSearch}
            filters={filters}
            listActions={listActions}
            queryList={queryList}
            actions={actions}
            empty={empty}
            ref={ref}
          />
        </ListViewProvider>
      </QueryResponseProvider>
    </QueryRequestProvider>
  )
})

export default PaginatedList;
