/* eslint-disable react-refresh/only-export-components */
/* eslint-disable react-hooks/exhaustive-deps */
import {FC, useContext, useState, useEffect, useMemo, ReactNode} from 'react'
import {useQuery} from 'react-query'
import {useQueryRequest} from './QueryRequestProvider'
import {
  createResponseContext,
  initialQueryResponse, initialQueryState,
  PaginationState,
  QUERIES,
  stringifyRequestQuery,
  WithChildren
} from '../../helpers'
import axios, {AxiosResponse} from 'axios';

const fetchData = (url: string,query: string): Promise<UsersQueryResponse> => {
  return axios
    .get(`${url}${url.indexOf('?') >= 0 ? '&' : '?'}${query}`)
    .then((d: AxiosResponse<UsersQueryResponse>) => d.data);
};

const QueryResponseContext = createResponseContext<User>(initialQueryResponse);
const QueryResponseProvider: FC<{children?: ReactNode, url: string, queryList: string}> = ({children, url, queryList}) => {
  const {state} = useQueryRequest()
  const [query, setQuery] = useState<string>(stringifyRequestQuery(state))
  const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state])

  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery)
    }
  }, [updatedQuery])

  const {
    isFetching,
    refetch,
    data: response,
  } = useQuery(
    `${queryList}-${query}`,
    () => {
      return fetchData(url, query)
    },
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  )

  return (
    <QueryResponseContext.Provider value={{isLoading: isFetching, refetch, response, query}}>
      {children}
    </QueryResponseContext.Provider>
  )
}

function useQueryResponse<T>() {
  return useContext(QueryResponseContext)
}

function useQueryResponseData<T>(){
  const {response} = useQueryResponse<T>()
  if (!response) {
    return []
  }

  return response?.data || []
}

const useQueryResponsePagination = () => {
  const defaultPaginationState: PaginationState = {
    links: [],
    ...initialQueryState,
  }

  const {response} = useQueryResponse()
  if (!response || !response.payload || !response.payload.pagination) {
    return defaultPaginationState
  }

  return response.payload.pagination
}

const useQueryResponseLoading = (): boolean => {
  const {isLoading} = useQueryResponse()
  return isLoading
}

export {
  QueryResponseProvider,
  useQueryResponse,
  useQueryResponseData,
  useQueryResponsePagination,
  useQueryResponseLoading,
}
