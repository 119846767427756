import React from 'react';
import clsx from "clsx";
import HIcon from "../../../app/modules/payment/components/HIcon.tsx";
import {toAbsoluteStatic} from "../../helpers";

export interface MediaInfoCellProps {
  media: {
    initial?: string;
    avatar?: string;
    title: string;
    subtitle: string;
    url?: string;
  };
  hideTextIcon?: boolean;
}

function MediaInfoCell({media, hideTextIcon = false}: MediaInfoCellProps) {
  if (!media.title && !media.subtitle && !media.avatar) {
    return null;
  }

  return (
    <div className='d-flex align-items-center'>
      <a href={media.url || '#'} className={'symbol symbol-40px me-2'}>
        <HIcon image={media.avatar} text={hideTextIcon ? undefined : media.initial || media.title} size={40} />
      </a>
      <div className='d-flex flex-column'>
        <a href={media.url || '#'} className='text-gray-800 text-hover-primary mb-1'>
          {media.title}
        </a>
        <span className={'fw-light'}>{media.subtitle}</span>
      </div>
    </div>
  );
}

export default MediaInfoCell;
